const acl = useAcl()

const navigation = [
    {
        id: 'sas-sales-payment',
        label: 'sales.sidebar.label',
        position: 30,
        path: 'invoices',
        icon: 'line-icons:files:file-check-02',
        permission: () =>
            acl.can('view_invoices') ||
            acl.can('view_estimates') ||
            acl.can('view_credit_notes'),
    },
]

export default navigation
